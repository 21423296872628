<template>
  <div id="safety_social_tips_page">
    <div class="container">
      <span class="page-title">Social & safety tips</span>
      <p>Here's a list of social and safety tips you need to keep in mind when carpooling for the first time.</p>

      <h2>Safety tips</h2>
      <p>When it comes to sharing journeys, everyone is responsible for their own personal safety. However, we do recommend that you read some simple safety measures outlined below:</p>
      <ul>
        <li>Arrange to meet for the first time in a public place.</li>
        <li>Don’t exchange home addresses with your travelling companion before you meet them.</li>
        <li>Show each other ID e.g. passports, student cards or driving licences, so you know you’re travelling with the right person.</li>
        <li>Trust your instincts; you are under no obligation to go ahead with any carpool arrangement. If you have any doubts about your travelling companion, for any reason, then you don’t have to travel with them.</li>
        <li>Check that the person you are sharing with has all the legal driving documents, such as driving license, car insurance, NCT and car tax.</li>
      </ul>

      <h2>Social tips</h2>
      <p>Carpooling may not work out for you and it’s okay to say that to your travelling companion, you don’t have to commit to carpool forever. Agree that each of you can stop at any stage and it’s not a personal thing.</p>
      <ul>
        <li>Good etiquette is required of all participants including polite and friendly behaviour, and punctuality to agreed appointments.</li>
        <li>All participants agree to inform their fellow participants as far in advance as possible of any cancellation of a lift, inability to provide or to attend for a lift, or any other interruption to the arrangements agreed between the participants.</li>
        <li>Set the ground rules of the arrangement at the start.</li>
        <li>As a driver, how long you will wait for passengers (5 minutes?)</li>
        <li>Text each other 30 minutes beforehand to remind and confirm.</li>
        <li>Is eating allowed in the car?</li>
        <li>Is the radio the driver’s prerogative, or will you swap stations?</li>
        <li>How are fuel costs recorded and what day will you pay?</li>
        <li>Agree you’ll talk to each other honestly if there are any issues.</li>
      </ul>

    </div>
  </div>
</template>

<script>

export default {
  data: () => ({
    faq: []
  }),

  
}
</script>

<style lang="scss" scoped>
  #safety_social_tips_page > .container {
    max-width: 600px;

    
		h2 {
			font-size: 20px;
			font-weight: 400;
			margin-top: 20px;
			margin-bottom: 5px;
    }
    
    ul {
			margin-left: 30px;
		}

		p, li {
			margin-bottom: 5px;
		}
  }
</style>